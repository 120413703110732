import { Enums } from '@flightscope/baseball-stats';
import { captureException } from '@sentry/vue';
import get from 'lodash/get';

const defaultTS = new Date();

const ESortOrder = Object.freeze({
  ASC: 'ASC',
  DESC: 'DESC'
});

const pairTS = (pair) => {
  try {
    // eslint-disable-next-line no-nested-ternary
    return (pair.pitch !== null
        ? pair.pitch[Enums.ResultData.CreateDate.key]
        : pair.hit !== null
        ? pair.hit[Enums.ResultData.CreateDate.key]
        : defaultTS);
  } catch (error) {
    captureException(error);
    return defaultTS;
  }
};

const pairPitchSpeed = (pair) => {
  return get(pair, `pitch.Data.${Enums.PitchData.Speed.key}`, null);
};

const pairExitSpeed = (pair) => {
  return get(pair, `hit.Data.${Enums.HitData.ExitSpeed.key}`, null);
};

const pairHitDistance = (pair) => {
  return get(pair, `hit.Data.${Enums.HitData.CarryDistance.key}`, null);
};

const ascComparer = (a, b) => {
  const isNullA = a === null;
  const isNullB = a === null;

  if (a === b) {
    return 0;
  }
  if (isNullA) {
    return 1;
  }
  if (isNullB) {
    return -1;
  }

  if (a > b) {
    return 1;
  }
  return -1;
};

const descComparer = (a, b) => {
  const isNullA = a === null;
  const isNullB = a === null;

  if (a === b) {
    return 0;
  }
  if (isNullA) {
    return 1;
  }
  if (isNullB) {
    return -1;
  }

  if (a > b) {
    return -1;
  }
  return 1;
};

const ascPairComparer = (pair, pairA, pairB) => {
  const a = pair(pairA.results);
  const b = pair(pairB.results);

  return ascComparer(a, b);
};

const descPairComparer = (pair, pairA, pairB) => {
  const a = pair(pairA.results);
  const b = pair(pairB.results);

  return descComparer(a, b);
};

const videosDateSorter = (pairA, pairB) => {
  const pairATS = pairTS(pairA.results);
  const pairBTS = pairTS(pairB.results);

  return Date.parse(pairATS) - Date.parse(pairBTS);
};

const ascPitchSpeedSorter = (pairA, pairB) => ascPairComparer(pairPitchSpeed, pairA, pairB);

const descPitchSpeedSorter = (pairA, pairB) => descPairComparer(pairPitchSpeed, pairA, pairB);

const ascExitSpeedSorter = (pairA, pairB) => ascPairComparer(pairExitSpeed, pairA, pairB);

const descExitSpeedSorter = (pairA, pairB) => descPairComparer(pairExitSpeed, pairA, pairB);

const ascHitDistanceSorter = (pairA, pairB) => ascPairComparer(pairHitDistance, pairA, pairB);

const descHitDistanceSorter = (pairA, pairB) => descPairComparer(pairHitDistance, pairA, pairB);


export {
  ESortOrder,
  videosDateSorter,
  ascPitchSpeedSorter,
  descPitchSpeedSorter,
  ascExitSpeedSorter,
  descExitSpeedSorter,
  ascHitDistanceSorter,
  descHitDistanceSorter,
};
