<template>
  <v-data-iterator
    v-model="selected"

    :loading="loading"
    :items="actions"
    :items-per-page="itemsPerPage"
    :footer-props="footerProps"

    :page.sync="page"
    :sort-by="sortBy[0]"
    :sort-desc="sortBy[1]"
    :custom-sort="sortActions"

    :single-select="true"
  >
    <template v-slot:loading>
      <v-alert>
        <v-progress-circular :indeterminate="loading" color="purple">m</v-progress-circular>
      </v-alert>
    </template>

    <template v-slot:header="{ index, item }">
      <v-toolbar class="align-center mt-2 py-1 mx-n2" flat dense>
        <v-toolbar-title class="title font-weight-regular body-2"> {{ actions.length }} {{ $vuetify.lang.t('Items') }}</v-toolbar-title>

        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-select
            class="sorting-selector py-2 ml-4"
            :label="$vuetify.lang.t('Sorting')"
            :menu-props="menuProps"
            outlined
            dense
            hide-details
            hide-no-data
            hide-selected
            v-model="sortBy"
            :items="sortByOptions"
          ></v-select>
        </template>
      </v-toolbar>
    </template>

    <template v-slot:default="{ items, isSelected, select }">
      <div ref="grid" class="grid py-5" v-resize="onResize">
        <video-thumbnail
          v-for="(item, i) in items"
          :key="`action-${item.id}`"

          :to="{ params: { action: item.id, vid: item.videos[0].id, share: undefined } }"

          class="video-thumbnail"

          bordered

          :active="isSelected(item)"
          :title="getActionTitle(item)"
          :dateISOString="getActionCreateDate(item)"
          :imageUrl="videoThumb(item)"
          :action="item"
          :action-index="i"
          :share-enabled="shareEnabled"
          :share-action="() => shareAction(item)"
          :video-action="() => select(item, !isSelected(item))"
        ></video-thumbnail>
      </div>
    </template>

    <template v-slot:no-results>
      <no-data :message="$vuetify.lang.t('No videos for selected filters.')"></no-data>
    </template>

    <template v-slot:no-data>
      <no-data :message="$vuetify.lang.t('No videos for selected filters.')"></no-data>
    </template>
  </v-data-iterator>
</template>

<script>
import { Enums, Mappers, Providers, Formatters } from '@flightscope/baseball-stats';
import { getActionTitle, getActionCreateDate } from '@/utils/formatters';
import {
  videosDateSorter,
  ascPitchSpeedSorter,
  descPitchSpeedSorter,
  ascExitSpeedSorter,
  descExitSpeedSorter,
  ascHitDistanceSorter,
  descHitDistanceSorter,
} from '../helpers/sorters';

const VideoThumbnail = () => import('@/modules/video-library/components/VideoThumbnail.vue');
const NoData = () => import('@/components/core/NoData.vue');

const selectMenuProps = {
  offsetY: true,
  maxHeight: 'auto',
};

export default {
  name: 'VideoList',

  inject: ['shareEnabled'],

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    actions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    thumbUrl: {
      type: String,
      default: '',
    },
    shareAction: {
      type: Function,
    },

    sortedActions: {
      type: Array,
    },
  },

  components: {
    VideoThumbnail,
    NoData,
  },

  data() {
    return {
      menuProps: selectMenuProps,
      footerProps: {
        disableItemsPerPage: true,
        itemsPerPageOptions: [],
      },
      itemsPerPage: 2,

      sortBy: [Enums.ResultData.CreateDate.key, true],

      // TODO: proper items
      sortByOptions: [
        { text: 'Newest', value: [Enums.ResultData.CreateDate.key, true] },
        { text: 'Oldest', value: [Enums.ResultData.CreateDate.key, false] },

        { text: `Highest ${Enums.PitchData.Speed.short}`, value: [Enums.PitchData.Speed.key, true] },
        { text: `Lowest ${Enums.PitchData.Speed.short}`, value: [Enums.PitchData.Speed.key, false] },

        { text: 'Highest Exit Velocity', value: [Enums.HitData.ExitSpeed.key, true] },
        { text: 'Lowest Exit Velocity', value: [Enums.HitData.ExitSpeed.key, false] },

        { text: `Longest ${Enums.HitData.CarryDistance.short}`, value: [Enums.HitData.CarryDistance.key, true] },
        { text: `Shortest ${Enums.HitData.CarryDistance.short}`, value: [Enums.HitData.CarryDistance.key, false] },
      ],
      page: 1,
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  mounted() {
    this.onResize();
  },

  methods: {
    getActionTitle,
    getActionCreateDate,

    onResize() {
      if (window.innerWidth > 1920) {
        this.itemsPerPage = 14;
        return;
      }
      if (window.innerWidth > 1440) {
        this.itemsPerPage = 12;
        return;
      }
      if (window.innerWidth > 1280) {
        this.itemsPerPage = 10;
        return;
      }
      if (window.innerWidth > 1024) {
        this.itemsPerPage = 8;
        return;
      }
      if (window.innerWidth > 768) {
        this.itemsPerPage = 6;
        return;
      }
      this.itemsPerPage = 4;
      console.table(window.innerWidth, this.itemsPerPage);
    },

    sortActions(items, sortBy, sortDesc, locale, customSorters) {
      if (!items?.length) return items;
      let [type] = sortBy;
      let [desc] = sortDesc;
      let sorted = [];
      let sorter;
      switch (type) {
        case Enums.ResultData.CreateDate.key:
          sorted = items.sort(videosDateSorter);
          if (desc) {
            sorted = sorted.reverse();
          }
          break;
        case Enums.PitchData.Speed.key:
          sorter = (desc) ? descPitchSpeedSorter : ascPitchSpeedSorter;
          sorted = items.sort(sorter);
          break;
        case Enums.HitData.ExitSpeed.key:
          sorter = (desc) ? descExitSpeedSorter : ascExitSpeedSorter;
          sorted = items.sort(sorter);
          break;
        case Enums.HitData.CarryDistance.key:
          sorter = (desc) ? descHitDistanceSorter : ascHitDistanceSorter;
          sorted = items.sort(sorter);
          break;
        default:
          sorted = items;
      }

      this.$emit('update:sortedActions', sorted);

      return sorted;
    },

    videoThumb(item) {
      let url;
      let thumbs = item.videos.find(v => v?.metadata?.Thumbnails?.length)?.metadata.Thumbnails;

      if  (thumbs) {
        url = thumbs.find(t => t.URL)?.URL;
      }

      return url || this.thumbUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  --grid-columns: 2;
  display: grid;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
  row-gap: 1em;
  column-gap: 1em;
  justify-content: center;

  @media (min-width: 768px) {
    --grid-columns: 3;
  }
  @media (min-width: 1024px) {
    --grid-columns: 4;
  }
  @media (min-width: 1280px) {
    --grid-columns: 5;
  }
  @media (min-width: 1440px) {
    --grid-columns: 6;
  }
  @media (min-width: 1920px) {
    --grid-columns: 7;
  }
}

.sorting-selector {
  max-width: 14em;
}
</style>
