import { Enums } from '@flightscope/baseball-stats';
import { GenericPitchType } from '@/enums/Generic';
import { enumerate } from '@/filters/enums';

function roundFractionals(number, fractionalDigit = 1) {
  let multiplier = 10 ** fractionalDigit;
  let rounded = (Math.round(number * multiplier) / multiplier).toString();

  return parseFloat(rounded);
}

function getPitchType(action) {
  // todo - enumerate
  if (action.results.hasPitch()) {
    return enumerate(action.results.getPitch()[Enums.ResultData.Data.key][Enums.PitchData.Classification.key], GenericPitchType);
  }

  return '-';
}

function getPitchResult(action) {
  // todo - enumerate
  if (action.results.hasPitch()) {
    let res = action.results.getPitch()[Enums.ResultData.Data.key][Enums.PitchData.Result.key];

    return enumerate(res, Enums.PitchResult);
  }

  return '-';
}

function getActionTitle(action) {
  if (action.results.hasPitch()) {
    return `${getPitchType(action)}, ${getPitchResult(action)}`;
  }
  if (action.results.hasHit()) {
    return 'Hit';
  }

  return '-';
}

function getActionCreateDate(action) {
  if (action.results.hasPitch()) {
    return action.results.getPitch()[Enums.ResultData.CreateDate.key].toISOString();
  }
  if (action.results.hasHit()) {
    return action.results.getHit()[Enums.ResultData.CreateDate.key].toISOString();
  }

  return '-';
}

export { roundFractionals, getActionTitle, getPitchResult, getActionCreateDate };
